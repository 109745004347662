<template>
  <div>
    <van-button @click="handleClick">生成</van-button>

    <p></p>
  </div>
</template>

<script>
import CarrierFreightService from '../utils/CarrierFreightService';
import { weight2gram, unitPrice2cent } from '@/utils/unit';
export default {
  data() {
    this.carrierFreightService = new CarrierFreightService();
    return {};
  },
  methods: {
    handleClick() {
      const payPlanInfo = {
        oilCardPayment: {
          fixed: false,
          value: 0
        },
        prePayment: {
          fixed: false,
          value: 0
        },
        arrivePayment: {
          fixed: false,
          value: 0
        },
        receiptPayment: {
          fixed: false,
          value: 0
        },
        servicePayment: {
          fixed: false,
          value: 0
        },
        wholePayment: {
          fixed: false,
          value: 0
        }
      };

      const weightUnit = 1;

      const goodsInfo = {
        loadingGoodsNumber: 0,
        loadingGoodsVolume: 0,
        loadingGoodsWeight: weight2gram(33.77, weightUnit),
        unloadingGoodsNumber: 0,
        unloadingGoodsVolume: 0,
        unloadingGoodsWeight: weight2gram(33.54, weightUnit)
      };

      const priceUnitType = 1;

      const carrierCostRule = {
        pricePlanType: 0, // 计费类型类型：0按单价，1按整车

        priceUnitType: priceUnitType, // 货单单位：1 元/吨，2 元/方 3 元/件
        settlementMethod: 1, // 计价维度: 0按装货数量，1按卸货数量，2装卸货的最小值
        smallChangeRule: 2, // 抹零规则：0：角分抹零，1：10元以下抹零，2：不抹零

        lossRule: 1, // 亏损规则：0:不扣除亏损 ，1:扣除亏损
        lossPrice: unitPrice2cent(1950, priceUnitType, weightUnit), // 亏损扣款单价
        allowLossNumber: weight2gram(0.15, weightUnit), // 允许亏损数量
        allowLossNumberUnit: 0, // 允许亏损数量单位：0:吨 ，1:% 千分比

        transportPrice: unitPrice2cent(47, priceUnitType, weightUnit), // 运输单价

        totalTransportPrice: 0 //  整车运费 pricePlanType 1 时 取这个：运输总价
      };

      // debugger;
      const carrierTransportCost = this.carrierFreightService
        .updateConfig(carrierCostRule, goodsInfo)
        .calcFreight(payPlanInfo);
      console.log(carrierTransportCost);
    }
  }
};
</script>

<style></style>
