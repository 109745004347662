/**
allowLossNumber 允许亏损数量
allowLossNumberUnit	允许亏损数量单位：0:吨 ，1:% 千分比
lossPrice	亏损扣款单价
lossRule 亏损规则：0:不扣除亏损 ，1:扣除亏损
numberLimit	单车件数
pricePlanType	计费类型类型：0按单价，1按整车，2一车一议(暂时没有该功能)
priceUnitType	货单单位：1 元/吨，2 元/方 3 元/件
settlementMethod 计价维度:0按装货数量，1按卸货数量，2装卸货的最小值
smallChangeRule	抹零规则：0：角分抹零，1：10元以下抹零，2：不抹零
transportPrice 运输单价
totalTransportPrice	整车运费 pricePlanType 1 时 取这个：运输总价
transportPrice 运输单价
volumeLimit 单车体积
weightLimit	单车重量
根据计价规则计算承运人运费 carrierTransportCost

// 【件数亏损不计算千分比的】

 */
/**
 * 运单需要有 模式标示
 * 自营有没有服务费用，其他两种有
 * 服务费付掉了，不用重新计算运费
 *
 * 计价规则变化后，支付计划重置成回单付
 */
import BigNumber from 'bignumber.js';
BigNumber.config({ EXPONENTIAL_AT: 1e9 });

import { PricePlanTypeMap, SettlementMethodMap, UnitPriceTypeMap, SmallChangeRuleMap, LossRuleMap } from '../constants';

export default class CarrierFreightService {
  /**
   * @param {*} costRule 计价规则
   * @param {*} goodsInfo 装货信息 {}
   */
  updateConfig(costRule, goodsInfo) {
    this.costRule = costRule;
    this.goodsInfo = goodsInfo;
    return this;
  }

  // 根据计价规则算运费
  calcFreight(payPlanInfo) {
    // 如果是整车付，直接 返回 运费
    if (this.costRule?.pricePlanType === PricePlanTypeMap.FixedPrice) {
      return this.costRule.totalTransportPrice;
    }

    const amountInfo = this._filterOperatorValue();
    let freight = 0;

    //0: 按装货数量
    if (this.costRule.settlementMethod === SettlementMethodMap.Loading || +amountInfo.unloadingAmount === 0) {
      freight = this.calcFreightByLoading(amountInfo.loadingAmount, amountInfo.unloadingAmount);
    } else if (this.costRule.settlementMethod === SettlementMethodMap.Unloading) {
      // 1: 按卸货
      freight = this.calcFreightByDelivered(amountInfo.loadingAmount, amountInfo.unloadingAmount);
    } else if (this.costRule.settlementMethod === SettlementMethodMap.Minimum) {
      // 2: 按装卸货最小值
      freight = this.calcFreightByMinimum(amountInfo.loadingAmount, amountInfo.unloadingAmount);
    }
    // 运费必须大于等于支付过的值
    const { paidTotal } = this._computePaidAndTotal(payPlanInfo);
    return parseFloat(new BigNumber(Math.max(freight, paidTotal)).toFixed(0));
  }

  /**
   * 按装货重量计算
   * 承运人运费 = 装货重量 * 运输单价 - 抹零规则
   */
  calcFreightByLoading(loadingAmount) {
    const freight = new BigNumber(loadingAmount).times(this._adjustValue(this.costRule.transportPrice)).toNumber();
    return this._convertUnit(this._smallChangeValue(freight));
  }

  /**
   * 按装卸货最小值计算
   * 承运人运费 = min[装货重量，卸货重量] * 运输单价 - 抹零规则
   */
  calcFreightByMinimum(loadingAmount, unloadingAmount) {
    const val = Math.min(this._adjustValue(loadingAmount), this._adjustValue(unloadingAmount));
    // 默认不扣除亏损
    let lossRule = this.costRule.lossRule ?? LossRuleMap.NoDeduct;

    if (+lossRule === LossRuleMap.NoDeduct) {
      const freight = new BigNumber(val).times(this._adjustValue(this.costRule.transportPrice)).toNumber();
      return this._convertUnit(this._smallChangeValue(freight));
    }

    if (+lossRule === LossRuleMap.Deduct) {
      const lossValue = this._calcLossValue(loadingAmount, unloadingAmount);
      const freight = new BigNumber(val)
        .times(this._adjustValue(this.costRule.transportPrice))
        .minus(lossValue)
        .toNumber();
      return this._convertUnit(this._smallChangeValue(freight));
    }
  }

  /**
   * 卸货规则计算
   * 1. 运费 = 卸货重量 * 运输单价 - 抹零规则
   * 2. 运费 = 卸货重量 * 运输单价 - (装货数量 - 卸货数量 - 允许亏损的数量) * 扣款单价 - 抹零规则
   *    |-- (装货数量 - 卸货数量 - 允许亏损的数量) < 0 不扣除亏损
   * 注意：
   */
  calcFreightByDelivered(loadingAmount, unloadingAmount) {
    // 默认不扣除亏损
    let lossRule = this.costRule.lossRule ?? LossRuleMap.NoDeduct;

    // 不扣除亏损
    if (+lossRule === LossRuleMap.NoDeduct) {
      const freight = new BigNumber(unloadingAmount).times(this._adjustValue(this.costRule.transportPrice)).toNumber();
      return this._convertUnit(this._smallChangeValue(freight));
    }

    // 扣除亏损
    if (+lossRule === LossRuleMap.Deduct) {
      const lossValue = this._calcLossValue(loadingAmount, unloadingAmount);
      return this._convertUnit(
        this._smallChangeValue(
          new BigNumber(unloadingAmount)
            .times(this._adjustValue(this.costRule.transportPrice))
            .minus(lossValue)
            .toNumber()
        )
      );
    }
  }

  /**
   * 允许亏损的价值
   * (装货 - 卸货 - 装货重量 * 千分比) * 亏损单价
   * 如果 允许亏损的数量 > 亏损结果 直接取 卸货 * 单价
   * 允许亏损的数量 < 亏损结果  -> 卸货 * 价格 - （亏损结果 - 允许亏损） * 亏损单价
   */
  _calcLossValue(loadingAmount, unloadingAmount) {
    const allowLossNumber = this._calcAllowLossAmount(loadingAmount, this.costRule.allowLossNumber || 0);
    const diff = new BigNumber(loadingAmount)
      .minus(unloadingAmount)
      .minus(allowLossNumber)
      .toNumber();
    // 亏损结果 > 允许亏损的数量
    if (diff > 0) {
      return new BigNumber(this._adjustValue(this.costRule.lossPrice)).times(diff).toNumber();
    }
    return 0;
  }

  // 允许亏损的值
  _calcAllowLossAmount(loadingAmount, value) {
    const val = this._adjustValue(value);
    // 默认为0
    const allowLossNumberUnit = this.costRule.allowLossNumberUnit || 0;
    if (allowLossNumberUnit === 0) {
      return val;
    }
    // 千分比 向上取整
    if (allowLossNumberUnit === 1) {
      return new BigNumber(loadingAmount)
        .times(val)
        .div(100000)
        .integerValue(BigNumber.ROUND_CEIL)
        .toNumber();
    }
  }

  // 抹零规则
  _smallChangeValue(value) {
    const val = this._adjustValue(value);
    if (val <= 0) return 0;
    // 默认不抹零
    const smallChangeRule = this.costRule.smallChangeRule ?? SmallChangeRuleMap.Zero;
    if (smallChangeRule === SmallChangeRuleMap.Zero) {
      return new BigNumber(val).toNumber();
    }

    /**
     * 重量 转 分/吨
     * 100 分/吨    1元/吨
     * 1200000 克   1.2吨
     * 重量 100 * 1200000 / 1000000 / 100  元/吨
     *
     * 体积
     * 100分/方           1元/方
     * 1200立方分米        1.2立方米
     * 100 * (1200 / 1000) / 100 元/方
     *
     * 件数
     * 100分/件           1元/件
     * 10件               10件
     * 100 * 10 / 100   元/件
     */
    const rateMap = {
      // 角分抹零
      [SmallChangeRuleMap.Dime]: {
        [UnitPriceTypeMap.Weight]: 100000000, // 1000000 吨 100 元 ---> 元/吨
        [UnitPriceTypeMap.Volume]: 100000, // 1000 方 100 元 ---> 元/方
        [UnitPriceTypeMap.Number]: 100
      },
      [SmallChangeRuleMap.Ten]: {
        [UnitPriceTypeMap.Weight]: 1000000000,
        [UnitPriceTypeMap.Volume]: 1000000,
        [UnitPriceTypeMap.Number]: 1000
      }
    };
    const rate = rateMap[smallChangeRule]?.[this.costRule.priceUnitType];
    if (rate) {
      return new BigNumber(val)
        .div(rate)
        .integerValue(BigNumber.ROUND_DOWN)
        .times(rate)
        .toNumber();
    }
    return value;
  }

  _filterOperatorValue() {
    // 根据单位筛选出需要的值
    let loadingAmount;
    let unloadingAmount;
    if (this.costRule.priceUnitType === UnitPriceTypeMap.Weight) {
      loadingAmount = this.goodsInfo.loadingGoodsWeight || 0;
      unloadingAmount = this.goodsInfo.unloadingGoodsWeight || 0;
    }
    if (this.costRule.priceUnitType === UnitPriceTypeMap.Volume) {
      loadingAmount = this.goodsInfo.loadingGoodsVolume || 0;
      unloadingAmount = this.goodsInfo.unloadingGoodsVolume || 0;
    }
    if (this.costRule.priceUnitType === UnitPriceTypeMap.Number) {
      loadingAmount = this.goodsInfo.loadingGoodsNumber || 0;
      unloadingAmount = this.goodsInfo.unloadingGoodsNumber || 0;
    }
    return { loadingAmount, unloadingAmount };
  }

  _convertUnit(val) {
    // 元/吨
    if (this.costRule.priceUnitType === UnitPriceTypeMap.Weight) {
      return new BigNumber(val)
        .div(1000000)
        .integerValue()
        .toNumber();
    }
    // 元/方
    if (this.costRule.priceUnitType === UnitPriceTypeMap.Volume) {
      return new BigNumber(val)
        .div(1000)
        .integerValue()
        .toNumber();
    }
    // 元/件
    if (this.costRule.priceUnitType === UnitPriceTypeMap.Number) {
      return new BigNumber(val).integerValue().toNumber();
    }
  }

  // 根据运费算支付计划
  calcPayPlan(carrierTransportCost, payPlanInfo) {
    const { paidAll, total } = this.paidInfo(carrierTransportCost, payPlanInfo);
    const priorityArr = this._composePriorityArr(paidAll, payPlanInfo);
    return this._minusChainValue(+carrierTransportCost, priorityArr, total);
  }

  paidInfo(carrierTransportCost, payPlanInfo) {
    const { paidTotal, total } = this._computePaidAndTotal(payPlanInfo);
    const paidAll =
      (+carrierTransportCost > 0 && paidTotal >= +carrierTransportCost) ||
      payPlanInfo.wholePayment.fixed ||
      payPlanInfo.servicePayment.fixed;
    return { total, paidAll, paidTotal };
  }

  /**
   * 组装成想要的数据结构
   * @param {*} carrierInfo 承运人信息
   * @param {*} payPlanInfo 支付计划，哪些支付过了
   * @param {*} field 当前在编辑哪个 【邮卡, ...】
   * @returns
   */
  _composePriorityArr(paidAll, payPlanInfo) {
    return [
      {
        name: 'receiptPayment',
        value: payPlanInfo.receiptPayment.value || 0,
        fixed: paidAll || payPlanInfo.receiptPayment.fixed
      },
      {
        name: 'arrivePayment',
        value: payPlanInfo.arrivePayment.value || 0,
        fixed: paidAll || payPlanInfo.arrivePayment.fixed
      },
      {
        name: 'prePayment',
        value: payPlanInfo.prePayment.value || 0,
        fixed: paidAll || payPlanInfo.prePayment.fixed
      },
      {
        name: 'oilCardPayment',
        value: payPlanInfo.oilCardPayment.value || 0,
        fixed: paidAll || payPlanInfo.oilCardPayment.fixed
      }
    ];
  }

  _minusChainValue(carrierTransportCost, priorityArr, total) {
    // 修改的差值
    let diff = new BigNumber(carrierTransportCost).minus(total).toNumber();
    for (let i = 0; i < priorityArr.length; i++) {
      const item = priorityArr[i];
      if (item.fixed) continue;
      diff = new BigNumber(+item.value).plus(diff).toNumber();
      if (diff >= 0) {
        item.value = diff;
        break;
      }
      item.value = 0;
    }
    console.log(priorityArr);
    return priorityArr;
  }

  // 计算锁定后剩余的值
  _computePaidAndTotal(payPlanInfo) {
    let paidTotal = 0;
    let total = 0;
    const keys = ['informationPayment', 'oilCardPayment', 'prePayment', 'arrivePayment', 'receiptPayment'];
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const item = payPlanInfo[key];
      if (item.fixed) {
        paidTotal = new BigNumber(item.value || 0).plus(paidTotal).toNumber();
      }
      total = new BigNumber(item.value || 0).plus(total).toNumber();
    }
    return {
      total,
      paidTotal
    };
  }

  _adjustValue(value) {
    return value || 0;
  }
}
